<template>
  <b-overlay :show="showOverlay">
    <validation-observer v-if="data" ref="updateDynamicPage" tag="div">

      <b-card class="data-edit-wrapper">
        <h2>Page Details</h2>

        <b-form class="mt-2">
          <b-row>
            <b-col class="" md="6">
              <b-form-group
                  label="Page Title"
              >
                <validation-provider
                    #default="{ errors }"
                    rules="required"
                >
                  <b-form-input
                      id="data-edit-seoTitle"
                      v-model="data.title"
                      :state="errors.length > 0 ? false:null"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col v-if="data" cols="12" md="12">
              <h2>Page Content</h2>
              <ckEditorMain ref="editorContent" :defaultContent="data.content" @getEditorContent="setEditorContent"
              ></ckEditorMain>
            </b-col>
          </b-row>

        </b-form>
      </b-card>
      <b-card>
        <b-row>
          <b-col v-if="data" cols="12" md="12">
            <h2>SEO</h2>
          </b-col>
          <b-col class="" md="4">
            <b-form-group
                label="Seo Title"
            >
              <validation-provider
                  #default="{ errors }"
                  rules="required"
              >
                <b-form-input
                    id="data-edit-seoTitle"
                    v-model="data.seoTitle"
                    :state="errors.length > 0 ? false:null"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col class="" md="4">
            <b-form-group
                label="Seo Url"
            >
              <validation-provider
                  #default="{ errors }"
                  rules="required"
              >
                <b-form-input
                    id="data-edit-seoTitle"
                    v-model="allCharactersToDash"
                    :state="errors.length > 0 ? false:null"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col class="" cols="12">
            <b-form-group
                label="Seo Description"
            >
              <validation-provider
                  #default="{ errors }"
                  rules="required"
              >

                <b-form-textarea
                    id="data-edit-seoTitle"
                    v-model="data.seoDescription"
                    :state="errors.length > 0 ? false:null"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" class="pl-1">
            <div class="col-md-12 ">
              <!--- serp Desktop --->
              <div class="serp-preview d-lg-flex d-none flex-column align-items-start">
                <div class="serp-title">
                  <h4 style="color: rgb(26, 13, 171)!important">
                    {{ data.seoTitle }}
                  </h4>
                </div>
                <div class="serp-url">
                  <h5 class="d-flex align-items-center flex-wrap" style="color: rgb(0, 102, 33)!important">
                    <span>https://skycoach.com/<span class="serp-arrow"></span></span>
                    <span style="overflow-wrap: anywhere">
                      {{ data.seoUrl.replaceAll(' ', '-') }}
                    </span>

                  </h5>
                </div>
                <div class="serp-description">
                  <h6 style="overflow-wrap: anywhere">
                    {{ data.seoDescription }}
                  </h6>
                </div>
              </div>
              <!--- serp Mobile --->
              <div class="serp-preview d-lg-none  p-1 d-flex flex-column align-items-start">
                <div class="serp-title">
                  <h6>
                    {{ data.seoTitle }}
                  </h6>
                </div>
                <div class="serp-url d-flex align-items-center flex-wrap">

                  <small>{{ data.seoUrl.replaceAll(' ', '-') }}/</small>
                  <small>
                    https://skycoach.com/
                    <small class="serp-arrow"></small>
                  </small>
                </div>
                <div class="serp-description">
                  <small>
                    {{ data.seoDescription }}
                  </small>
                </div>
              </div>
            </div>
          </b-col>
          <b-col class="my-3" cols="12" md="2">
            <b-button class="btn " type="button" variant="primary" @click="updateData">Submit</b-button>
          </b-col>
        </b-row>
      </b-card>

    </validation-observer>

  </b-overlay>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem'
import {
  BButton,
  BCard,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BOverlay,
  BRow,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import ckEditorMain from '@/views/components/ckEditorMain'
import { PageCreateRequest, PageGetByIdRequest, PageUpdateRequest } from '@/libs/Api/Page'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import Helper from '@/libs/Helper'

export default {
  components: {
    BFormTextarea,
    BForm,
    ValidationProvider,
    ValidationObserver,
    ckEditorMain,
    AppCollapse,
    AppCollapseItem,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    BOverlay,
    vSelect,
  },
  name: 'DynamicPageCreate',
  title: 'create dynamic page',
  data() {
    return {
      showOverlay: false,
      required,
      baseUrl: 'https://skycoach.gg/dynamics',
      data: null
    }
  },
  computed: {
    allCharactersToDash: {
      get: function () {
        return this.data.seoUrl
      },
      set: function (newValue) {
        // This setter is getting number, replace all commas with empty str
        // Then start to separate numbers with ',' from beginning to prevent
        // from data corruption
        if (newValue) {
          this.data.seoUrl = newValue
          // Remove all characters that are NOT number
          this.data.seoUrl = this.data.seoUrl.replace(/[+*!@#$%^&*()_;:~`»«,×='"|<>/?{}\-/\.]/g, '-')
          this.data.seoUrl = this.data.seoUrl.replaceAll(' ', '-')
        } else if (!newValue || this.data.seoUrl === '') {
          this.data.seoUrl = null
        }
      },
    },
  },
  async created() {
    await this.getData()
  },
  methods: {
    async getData() {
      let _this = this
      _this.overlay = true

      let pageGetByIdRequest = new PageGetByIdRequest(_this)
      pageGetByIdRequest.setParams({ pageId: _this.$route.params.id })
      await pageGetByIdRequest.fetch(function (content) {
        _this.overlay = false
        _this.data = content.data
      }, function (error) {
        _this.overlay = false
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
      })
    },
    setEditorContent(content) {
      this.data.content = content
    },
    async updateData() {
      let _this = this
      Helper.validateForm(_this, 'updateDynamicPage', async () => {

        _this.showOverlay = true

        let pageUpdateRequest = new PageUpdateRequest(_this)
        pageUpdateRequest.setParams(_this.data)
        await pageUpdateRequest.fetch(function (content) {
          _this.showOverlay = false
          if (content.isSuccess) {
            _this.$toast({
              component: ToastificationContent,
              position: 'bottom-center',
              props: {
                title: `Done`,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            _this.$router.push('/apps/site-setting/dynamic-pages')
          } else {
            return _this.$toast({
              component: ToastificationContent,
              position: 'bottom-center',
              props: {
                text: content.message,
                title: `error`,
                variant: 'danger',
              },
            })
          }
        }, function (error) {
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              text: error.message,
              title: `error`,
              variant: 'danger',
            },
          })
          _this.showOverlay = false
        })
      })
    },

    async copyText() {
      await navigator.clipboard.writeText(this.data.seoUrl)
      this.$toast({
        component: ToastificationContent,
        position: 'bottom-center',
        props: {
          title: `Copied`,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    },
  }
}
</script>

<style scoped>

</style>
